<template>
  <div class="content">
    <!-- 导航栏 -->
    <el-header class="zg--header--main">
      <img class="zg--header--logo"  src="https://image.qll-times.com/2021/04/e08a661764ea49c6a03d5278aa74283e.png">
      <label class="zg--header--title">商机圈子平台</label>
    </el-header>
    <div class="title">新增商机</div>
    <!-- form表单 -->
    <div class="addContent">
      <el-form :model="form" label-width="110px" :rules="formRules" ref="formRulesRef">
        <el-form-item label="项目名称"  prop="projectName">
          <el-input v-model="form.projectName" style=" width: 350px"   placeholder="请输入项目名称"></el-input>
        </el-form-item>
        <el-form-item label="项目封面" prop="projectImg">
          <div class="logo" @click="uploadImage">
            <img v-if="form.projectImg" :src="form.projectImg">
            <span class="add el-icon-plus"></span>
          </div>
          <!-- <div class="item-content " style="margin-bottom:10px;">           
            <file-upload accepts=".png,.jpeg,.jpg" @on-success="uploadLogo">
              <div class="brochure-logo" >
                <img v-if="form.projectImg" :src="form.projectImg" style="width:148px;height:100px;border-radius: 5px;"> 
                <template  v-else>
                  <div class="upload-icon">
                    <p class="add">+</p><p>立即上传</p>
                  </div>
                </template>            
                
              </div>
            </file-upload>
          </div> -->
        </el-form-item>
        <!-- 项目开始结束时间 -->

        <!-- <el-form-item label="项目开始时间" prop="startTime">
            <el-date-picker type="date" placeholder="选择日期" v-model="form.startTime" style="width: 200px"></el-date-picker>
        </el-form-item>
        <el-form-item label="项目结束时间" prop="endTime">
          <el-date-picker type="date" placeholder="选择日期" v-model="form.endTime" style="width: 200px"></el-date-picker>
        </el-form-item> -->


        <el-form-item label="所属行业">
       
          <el-checkbox-group v-model="form.industry">
            <el-checkbox  v-for="(item,index) in industryList" :key="index" :label="item" >{{item}}</el-checkbox>
          </el-checkbox-group>
             <!-- 新增标签 -->
          <el-input
              style=" width: 80px;margin:0 10px"
                class="input-new-tag"
                v-if="inputVisible.industry"
                v-model="inputValue.industry"
                ref="saveTagInput"
                size="small"
                @keyup.enter.native="saveIndustry"
                 @blur="saveIndustry"
          ></el-input>
          <el-button style="margin:0 10px" class="button-new-tag" type="primary" size="small" @click="showIndustry">新增行业</el-button>
        </el-form-item>
        <el-form-item label="项目标签">

          <el-checkbox-group v-model="form.tag"  :max="3">
            <el-checkbox  v-for="(item,index) in tagList" :key="index" :label="item" >{{item}}</el-checkbox>
          </el-checkbox-group>
          <el-button  class="button-new-tag" size="small" >已选中({{this.form.tag.length}})个</el-button>
          <!-- 新增标签 -->
          <el-input
              style=" width: 80px;margin:0 10px"
                class="input-new-tag"
                v-if="inputVisible.tag"
                v-model="inputValue.tag"
                ref="saveTagInput"
                size="small"
                @keyup.enter.native="saveTag"
                 @blur="saveTag"
          ></el-input>
          <el-button style="margin:0 10px" class="button-new-tag" type="primary" size="small" @click="showTag">新增标签</el-button>
        </el-form-item>
        <el-form-item label="合作模式" prop="cooperationModel">
          <el-checkbox-group  v-model="form.cooperationModel">
            <div class="cooperationModel">
              <el-checkbox  v-for="(item,index) in modelList" :key="index" :label="item">{{item}}</el-checkbox>
            </div>
             <!-- 新增标签 -->
            <el-input
              maxlength="4" style=" width: 80px;margin:0 10px"
                class="input-new-tag"
                v-if="inputVisible.model"
                v-model="inputValue.model"
                ref="saveTagInput"
                size="small"
                @keyup.enter.native="saveModel"
                 @blur="saveModel"
            ></el-input>
            <el-button style="margin:0 10px" class="button-new-tag" type="primary" size="small" @click="showModel">新增模式</el-button>
          </el-checkbox-group>

         


        </el-form-item>
        <el-form-item label="项目详情" prop="projectDetails">
          <el-input
            type="textarea"
            autosize  style=" width: 350px;" 
            placeholder="请输入项目详情..."
            v-model="form.projectDetails">
          </el-input>          
          <div class="img-item" v-if="form.projectDetailsImg.length != 0">
            <div v-if="form.projectDetailsImg.constructor ===Array">
              <div v-for="(item,index) in form.projectDetailsImg" :key="index" class="image-item">
                <img :src="item">
                <div class="image-handel">
                  <span @click="delUploadimage(index,form.projectDetailsImg)" class="el-icon-delete"></span>
                </div>
              </div>    
            </div>  
          </div>   
          <!-- 上传项目详情图片 -->
          <div class="upload-box" v-if="form.projectDetailsImg.length < 3 " >
          <div class="item-content">
            <file-upload accepts=".png,.jpeg,.jpg" @on-success="uploadProjectDetails">
              <div class="brochure-logo">             
                <div class="upload-icon">
                  <p class="add">+</p><p>立即上传</p>
                </div>
              </div>
            </file-upload>
               <div class="tips">最多可上传三张</div>
          </div>
          </div>
        </el-form-item>


        <el-form-item label="产品服务" >
          <el-input
            type="textarea"
            autosize  style=" width: 350px;" 
            placeholder="请输入产品服务..."
            v-model="form.serviceDescription">
          </el-input>
          <div class="img-item" v-if="form.serviceDescriptionImg.length != 0 ">
            <div v-if="form.serviceDescriptionImg.constructor ===Array">
              <div v-for="(item,index) in form.serviceDescriptionImg" :key="index" class="image-item">
                <img :src="item">
                <div class="image-handel">
                  <span @click="delUploadimage(index,form.serviceDescriptionImg)" class="el-icon-delete"></span>
                </div>
              </div>      
            </div>
          </div>     
          <!-- 上传产品服务图片 -->
          <div class="upload-box" v-if="form.serviceDescriptionImg.length <3">
            <div class="item-content"> 
            <file-upload accepts=".png,.jpeg,.jpg" @on-success="uploadServiceDescription">
             <div class="brochure-logo">                                  
                  <div class="upload-icon">
                    <p class="add">+</p><p>立即上传</p>
                  </div>
                </div>            
            </file-upload>
            </div>
            <div class="tips">最多可上传三张</div>
          </div>
        </el-form-item>


        <el-form-item label="团队实力" >
          <el-input
            type="textarea"
            autosize  style=" width: 350px;" 
            placeholder="请输入团队实力..."
            v-model="form.teamDescription">
          </el-input>
          <div class="img-item" v-if="form.teamDescriptionImg.length != 0">
            <div v-if="form.teamDescriptionImg.constructor ===Array">
              <div v-for="(item,index) in form.teamDescriptionImg" :key="index" class="image-item">
                <img :src="item">
                <div class="image-handel">
                  <span @click="delUploadimage(index,form.teamDescriptionImg)" class="el-icon-delete"></span>
                </div>
              </div>  
            </div>    
          </div> 
          <!-- 上传团队实力图片 -->
          <div class="upload-box"  v-if="form.teamDescriptionImg.length < 3" >
            <div class="item-content"> 
              <file-upload accepts=".png,.jpeg,.jpg" @on-success="uploadTeamDescription">
                <div class="brochure-logo">                                  
                    <div class="upload-icon">
                      <p class="add">+</p><p>立即上传</p>
                    </div>
                  </div>             
              </file-upload>
            </div>
            <div class="tips">最多可上传三张</div>
          </div>  
        </el-form-item>

        <el-form-item label="合作优势" >
          <el-input
            type="textarea"
            autosize  style=" width: 350px;" 
            placeholder="请输入合作优势..."
            v-model="form.advantagesOfCooperation">
          </el-input>
           <div class="img-item" v-if="form.advantagesOfCooperationImg.length != 0">
            <div v-if="form.advantagesOfCooperationImg.constructor ===Array">
              <div v-for="(item,index) in form.advantagesOfCooperationImg" :key="index" class="image-item">
                <img :src="item">
                <div class="image-handel">
                  <span @click="delUploadimage(index,form.advantagesOfCooperationImg)" class="el-icon-delete"></span>
                </div>
              </div> 
            </div>     
          </div>    
          <!-- 上传合作优势图片 -->
          <div class="upload-box" v-if="form.advantagesOfCooperationImg.length < 3">
          <div class="item-content" >
            <file-upload accepts=".png,.jpeg,.jpg" @on-success="uploadAdvantagesOfCooperation"  >
              <div class="brochure-logo">             
                <div class="upload-icon">
                  <p class="add">+</p><p>立即上传</p>
                </div>
              </div>
            </file-upload>
           <div class="tips">最多可上传三张</div>
          </div>  
          </div>
        </el-form-item>

        <el-form-item label="选择销售"  prop="salename">
          <el-select
           style=" width: 300px"
              v-model="form.owner"  
              filterable 
              placeholder="请选择销售"
              >
              <el-option
                v-for="item in salename"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
          </el-select>
      </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="setRelease(form)">发布</el-button>
          <el-button>取消</el-button>
        </el-form-item>
    </el-form>
    </div>
    
  </div>
</template>
<script>
import UploadImage from '@/websites/cms/js/dialog'
import upload from "@/websites/cms/components/FileUpload"
  export default {
    data() {
      return {
        form: {
          projectName: '',//项目名称
          industry: [],//行业
          tag:[],//项目标签
          cooperationModel:[],//合作模式
          projectImg:'',//项目图标
          projectDetails:'',//项目详情
          projectDetailsImg:[],//项目详情图片
          teamDescription:'',//团队描述
          teamDescriptionImg:[],//团队图片
          serviceDescription:'',//服务描述
          serviceDescriptionImg:[],//服务图片
          advantagesOfCooperation:'',//合作优势
          advantagesOfCooperationImg:[],//合作优势图片
          owner:'',//销售id
        },
        formcs: {
          projectName: '',//项目名称
          industry: [],//行业
          tag:[],//项目标签
          cooperationModel:[],//合作模式
          projectImg:'',//项目图标
          projectDetails:'',//项目详情
          projectDetailsImg:[],//项目详情图片
          teamDescription:'',//团队描述
          teamDescriptionImg:[],//团队图片
          serviceDescription:'',//服务描述
          serviceDescriptionImg:[],//服务图片
          advantagesOfCooperation:'',//合作优势
          advantagesOfCooperationImg:[],//合作优势图片
          owner:'',//销售id
        },
        formRules: {//验证
          projectName:[ {required: true, message: '请输入项目名称', trigger: 'blur'}],
          projectImg:[ {required: true, message: '请上传项目图标'}],
          projectDetails:[ {required: true, message: '请输入项目详情', trigger: 'blur'}],
        },
        salename:[],//销售
        modelList:['直销','渠道','加盟'],//合作模式
        tagList:['SCRM','小程序','APP','打字机','零售'],
        industryList:['房地产行业','金融行业','汽车行业','美妆行业','医疗行业'],
        inputValue:{//新增输入框
          model:'',
          tag:'',
          industry:''
        },
        inputVisible: {//新增输入框显示
          model:false,
          tag:false,
          industry:false
        }
      }
    },
    created() {
      this.getOwnerList()
    },
    methods: {
      async getOwnerList() {
        let res = await this.$ZGManager.businessOwner();
        this.salename = res.data
        console.log(res);
      },
      // 上传封面图
      // uploadLogo(data){
      //   this.form.projectImg = data.url;
      // },
      uploadImage(){
        UploadImage.show({
          type:"crooperImage",
          cropWidth: 250,
          cropHeight: 200,
          fixedArray: ['5', '4'],
          isfixed: true,
          isfixedBox:true,
          selected: (item) => {
            console.log(item);
            this.form.projectImg = item.url;
          }
        });
      },
      uploadProjectDetails(data) {
        this.form.projectDetailsImg.push(data.url);
      },
      uploadServiceDescription(data) {
        console.log(data);
        this.form.serviceDescriptionImg.push(data.url);
      },
      uploadTeamDescription(data) {
        this.form.teamDescriptionImg.push(data.url);
      },
      uploadAdvantagesOfCooperation(data) {
        this.form.advantagesOfCooperationImg.push(data.url);
      },
      //发布
      async setRelease(form) {
        console.log(this.form);
        this.$refs.formRulesRef.validate(async (valid) => {
          if (valid) {
            for (const key in this.form) {
               if(this.form[key].constructor == Array) {
                this.form[key] = String(this.form[key])
              }
            }
            let res = await this.$ZGManager.businessAdd(this.form)
            if (res.status !== 200) return;
            console.log('==============',res);
            Object.assign(this.form,this.formcs);
            for (const key in this.form) {
              if (this.form[key].constructor == Array) {
                this.form[key] = [];           
              }
            }
            let timer = setTimeout(()=>{window.close()},1000)
            this.$message.success('发布成功')
          } else {
            console.log('error');
            return false;
          }         
        })
      },
      //删除图片
      delUploadimage(index,arr) {
        arr.splice(index,1)
      },
      //保存合作模式新增
      saveModel() {
        if (this.inputValue.model) {
          this.modelList.push(this.inputValue.model);
        }
        this.inputValue.model='';
        this.inputVisible.model = false;
      },
      //保存合作模式新增
      saveModel() {
        if (this.inputValue.model) {
          this.modelList.push(this.inputValue.model);
        }
        this.inputValue.model='';
        this.inputVisible.model = false;
      },
      //保存合作模式新增
      saveIndustry() {
        if (this.inputValue.industry) {
          this.industryList.push(this.inputValue.industry);
        }
        this.inputValue.industry='';
        this.inputVisible.industry = false;
      },
        //保存合作模式新增
      saveTag() {
        if (this.inputValue.tag) {
          this.tagList.push(this.inputValue.tag);
        }
        this.inputValue.tag='';
        this.inputVisible.tag = false;
      },
      //显示模式输入框
      showModel() {
        this.inputVisible.model = true;
      },
         //显示模式输入框
      showTag() {
        this.inputVisible.tag = true;
      },
         //显示模式输入框
      showIndustry() {
        this.inputVisible.industry = true;
      }
    },
    components: {
      'file-upload':upload
    }
  }
</script>
<style lang="scss" scoped>
.content{
  padding: 10px;
  background-color: #fff;
  margin-bottom: 50px;
  font-size: 14px;
  .title {
    font-weight: 600;
    padding: 20px 0 0 150px;
  }
  .addContent {
    margin: 30px 0 0 180px;
    .el-radio {
      margin-top: 12px;
      display: block;
    }
    .el-checkbox {
      display: block;
    }
    .img-item {
      // display: inline-block;
      height: 148px;
      border-radius: 6px;
      padding: 5px;
      img {
        width: 148px;
        height: 100%;
        display: inline-block;
        margin:0  15px 0 0;
        border-radius: 5px;
      }     
    }
     .el-checkbox {
        display: inline-block!important;
      }

  }

  p {
    height: 20px;
    width: 100%;
  }
}
.item-content {
  width: 100px;
  height: 60px;
  .upload-icon {
    margin: 10px;
    width: 100px;
    height: 60px;
    border: 1px solid #999999;
  }

}
.upload-box {
  position: relative;
  .tips {
    position: absolute;
    top: 10px;
    left: 130px;
    color: #999999;
 }
}
  .image-item{
    width: 148px;
    height: 148px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    position: relative;
    float: left;
    margin: 0 10px 10px 0;
    img{
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &:hover{
      .image-handel{
        display: block;
      }
    }
    .image-handel{
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.7);
      position: relative;
      z-index: 8;
      display: none;
      // margin-top: 10px;
      span{
        display: inline-block;
        width: 49px;
        height: 104px;
        line-height: 104px;
        color: #ffffff;
        font-size: 24px;
        margin:25px 0 0 40px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  ::v-deep .el-textarea__inner {
    height: 150px!important;
  }
  ::v-deep .el-form-item__error {
    top: 25%;
    left: 360px;
  }
  .logo{
    width: 200px;
    height: 160px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    overflow: hidden;
    .add{
      display: inline-block;
      width: 100%;
      height: 100%;
      line-height: 150px;
      text-align: center;
      font-size: 20px;
      color: #bbbbbb;
    }
    img{
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
</style>